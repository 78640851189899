<template>
  <div id="dashboard">
    <b-container fluid>
      <b-row>
        <b-col></b-col>
        <b-col>
          <h2 style="color: white; margin-bottom: 1em">Dashboard</h2>
        </b-col>
        <b-col></b-col>
      </b-row>
      <b-row>
        <b-col>
          <SideMenu></SideMenu>
        </b-col>
        <b-col class="dashboard">
          <b-row>
            <b-button-group size="small">
              <router-link class="link-color" :to="{ name: 'CmsCashCountCreate' }" @click.prevent>
                <b-button type="submit" style="margin-right: 1em;" variant="success">Add Cash Count</b-button>
              </router-link>
              <router-link class="link-color" :to="{ name: 'CmsEntryCreate' }" @click.prevent>
                <b-button type="submit" style="margin-right: 1em;" variant="success">Add Cash Entry</b-button>
              </router-link>
            </b-button-group>
          </b-row>
          <b-row cols="2">
              <b-card
                      border-variant="primary"
                      header="Total Cash on Hand"
                      header-bg-variant="primary"
                      header-text-variant="white"
                      align="center"
                      style="margin-top: 3em"
              >
                <b-card-text>€{{ totalCashOnHand.toFixed(2) }}</b-card-text>
              </b-card>
          </b-row>
        </b-col>
        <b-col></b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
import SideMenu from '@/components/SideMenu.vue'

export default {
  data() {
    return {
      loading: true,
      entries: [],
      totalCashOnHand: null
    }
  },
  components: {
    SideMenu
  },
  beforeCreate() {
    this.axios
        .get('https://batavia-backend.herokuapp.com/api/entry')
        .then(response => (
            this.entries = response.data,
            this.totalCashOnHand = this.getTotalCashOnHand(this.entries)
        ))
        .finally(() => {
          this.loading = false
        })
  },
  methods: {
    getTotalCashOnHand(entries) {
      let totalTakeAways = 0.00
      let totalExpenses = 0.00

      entries.forEach(function (entry) {
        if (entry['type_id'] === 1) {
          totalTakeAways += entry['amount']
        } else if (entry['type_id'] === 21) {
          totalExpenses += entry['amount']
        }
      })

      return totalTakeAways - totalExpenses
    }
  }
}
</script>

<style scoped>
  .dashboard {
    overflow: auto;
    height: 400px;
  }
</style>
